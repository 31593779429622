import React from 'react';
import { Transition } from 'react-transition-group';
import { reflow } from 'utils/transition';
import Section from 'components/Section';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './Experience.css';
import { media } from 'utils/style';
import { useWindowSize } from 'hooks';

const useStyles = makeStyles(theme => ({
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    backgroundColor: 'transparent',
  },
}));

const Achievements = ({ id, visible, sectionRef, objectList, title }) => {
  const titleId = `${id}-title`;
  const classes = useStyles();
  const { width } = useWindowSize();
  const isMobile = width <= media.tablet;
  window.browserWidth = width;
  return (
    <Section
      className="awards"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        <>
          <Grid container>
            <Grid item xs={12} className="center">
              <h4 className="heading--level-3 title" style={{ zIndex: 1 }}>
                {title}
              </h4>
            </Grid>
            <Grid item xs={12}>
              <Timeline align={isMobile ? 'left' : 'alternate'}>
                {objectList.map(element => (
                  <TimelineItem key={element.title + element.description}>
                    <TimelineOppositeContent className="oppositeList">
                      <Typography variant="body2" color="textSecondary" className="date">
                        {element.date}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className="dot">{element.icon}</TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={2} className={classes.paper}>
                        <div className="paper">
                          <Typography variant="h6" component="h4" className="heading">
                            {element.title}
                          </Typography>
                          <Typography className="text text--size-s">
                            {element.description}
                          </Typography>
                        </div>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </Grid>
        </>
      </Transition>
    </Section>
  );
};

export default Achievements;
