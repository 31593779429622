import { Fragment, lazy, Suspense } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import Link from 'components/Link';
import { Button } from 'components/Button';
import DecoderText from 'components/DecoderText';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { ReactComponent as KatakanaProfile } from 'assets/katakana-profile.svg';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import './Profile.css';
import { trackButton } from 'utils/ga';
import VisuallyHidden from 'components/VisuallyHidden';

const Portrait = lazy(() => import('components/Portrait'));
const shouldShowPortrait = false;

const ProfileText = ({ status, titleId }) => (
  <Fragment>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="A little about me," start={status !== 'exited'} delay={500} />
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      I'm Saurabh<VisuallyHidden> Londhe</VisuallyHidden>, I work as frontend developer at{' '}
      <Link href="https://www.kohler.co.in/">Kohler</Link>. I'm Ex-President of RIT India
      🐧 Linux Users' Group, Red Hat Certified Engineer, Red Hat Certified System
      Administrator. So devops interests me. I work on various open source technologies
      and support it.
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      In my free time I like to watch movies (Sci-Fi, thriller, horror are my favorite
      genres). <br />I like to ride, I own a KTM duke 🏍.
    </Text>

    <div
      className={classNames(
        'project-summary__button',
        `project-summary__button--${status}`
      )}
    >
      <Button
        iconHoverShift
        href="https://saurabhlondhe.com/Saurabh_Londhe_Resume.pdf"
        target="_blank"
        iconEnd="arrowRight"
        onClick={() => trackButton('Resume')}
      >
        Resume
      </Button>
    </div>
  </Fragment>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <ProfileText status={status} titleId={titleId} />
              {/* <Button
                secondary
                className={classNames('profile__button', `profile__button--${status}`)}
                href="/contact"
                icon="send"
              >
                Send me a message
              </Button> */}
            </div>
            {shouldShowPortrait && (
              <div className="profile__column">
                {/* <div className="profile__tag" aria-hidden>
                  <Divider
                    notchWidth="64px"
                    notchHeight="8px"
                    collapsed={status !== 'entered'}
                    collapseDelay={1000}
                  />
                  <div
                    className={classNames(
                      'profile__tag-text',
                      `profile__tag-text--${status}`
                    )}
                  >
                    About Me
                  </div>
                </div> */}
                <div className="profile__image-wrapper">
                  {!prerender && (
                    <Suspense fallback={null}>
                      {/* <Portrait
                        className={classNames(
                          'profile__image',
                          `profile__image--${status}`
                        )}
                        delay={100}
                      /> */}
                      {/* <img
                        src={profile}
                        className="profile-img"
                        alt="Saurabh Londhe Profile"
                      /> */}
                    </Suspense>
                  )}
                  <KatakanaProfile
                    className={classNames('profile__svg', `profile__svg--${status}`)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
