import React from 'react';
import { Transition } from 'react-transition-group';
import { reflow } from 'utils/transition';
import Section from 'components/Section';
import images from 'utils/images';
import { media } from 'utils/style';
import { useWindowSize } from 'hooks';
import { Grid, Tooltip } from '@material-ui/core';
import './Skills.css';

const Skills = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;
  const { width } = useWindowSize();
  const isMobile = width <= media.tablet;
  return (
    <Section
      className="skills"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      {' '}
      <Transition in={visible} timeout={0} onEnter={reflow}>
        <Grid container>
          <Grid item xs={12} className="center" style={{ position: 'relative' }}>
            <h4 className="heading--level-3 title">Tech I'm familiar with</h4>
          </Grid>
          {/* {isMobile ? ( */}
          <canvas id="canvasForSkills"></canvas>
          <Grid container className="skillContainer">
            {images.map(image => (
              <Grid
                item
                xs={3}
                sm={1}
                md={1}
                lg={1}
                className="skillItem"
                key={image.title}
              >
                <Tooltip title={<b>{image.title}</b>} arrow>
                  <img
                    src={image.src}
                    alt={image.title}
                    className="skillImage"
                    style={image.style}
                  />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
          {/* ) : (
          )} */}
        </Grid>
      </Transition>
    </Section>
  );
};
export default Skills;
