import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Intro from './Intro';
import ProjectSummary from './ProjectSummary';
import Profile from './Profile';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition, useWindowSize } from 'hooks';
import deviceModelsTextureLarge0 from 'assets/device-models-phone-large0.jpg';
import dealerPortalXS from 'assets/dealer-portal-xs.png';
import dealerPortalProductsPage from 'assets/dealerportal-products.PNG';
import deviceModelsTexturePlaceholder from 'assets/device-models-phone-large.jpg';
import dttTexture from 'assets/ces.png';
import dttTextureLarge from 'assets/ces.png';
import dttTexturePlaceholder from 'assets/dtt-placeholder.jpg';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import portrait from 'assets/portrait.glb';
import VaroBankHomePage from 'assets/varo-bank-home-page.svg';
import VaroMoneyFeature from 'assets/varo-money-p1.png';
import './index.css';
import fillCanvas from 'utils/canvaPos';
import Experience from './Experience';
import { media } from 'utils/style';
import Skills from './Skills';
import { awardsAndAchievements, experience as workingExp } from 'constants/awards';
import Achievements from './Achievements';

const disciplines = ['DevOps'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const dealerPortal = useRef();
  const varoBank = useRef();
  const varoMoney = useRef();
  const gamifiedPortal = useRef();
  const about = useRef();
  const skills = useRef();
  const awards = useRef();
  const experience = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();
  const { width } = useWindowSize();
  const isMobile = width <= media.tablet;

  useEffect(() => {
    !isMobile && fillCanvas();
  }, [isMobile]);

  useEffect(() => {
    const revealSections = [
      intro,
      dealerPortal,
      varoBank,
      varoMoney,
      gamifiedPortal,
      skills,
      experience,
      awards,
      about,
    ];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [
        intro,
        dealerPortal,
        varoBank,
        varoMoney,
        gamifiedPortal,
        skills,
        experience,
        about,
      ];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home scroll-container">
      <Helmet>
        <title>Saurabh Londhe | Developer</title>
        <meta
          name="description"
          content="Portfolio of Saurabh Londhe - a developer with rich frontend experience and DevOps knowledge."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
        <link rel="prefetch" href={portrait} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />

      <ProjectSummary
        id="project"
        alternate
        sectionRef={dealerPortal}
        visible={visibleSections.includes(dealerPortal.current)}
        index={1}
        title="Kohler Dealer Portal"
        description="The Partner Dealer portal is login-based application for Kohler’s Residential Dealer community. It's redesigned partner experience that enhances and simplified the UI aligning to the Kohler Energy brand, improve technical capabilities, and consolidate essential resources in one location.​"
        // buttonText="View Project"
        // buttonLink="/projects/kohler-dealer-portal"
        model={{
          type: 'phone',
          alt: 'Varo money screenshots',
          textures: [
            {
              src: dealerPortalXS,
              srcSet: `${dealerPortalXS} 254w, ${dealerPortalXS} 508w`,
              placeholder: deviceModelsTexturePlaceholder,
            },
            {
              src: dealerPortalProductsPage,
              srcSet: `${dealerPortalProductsPage} 254w, ${dealerPortalProductsPage} 508w`,
              placeholder: deviceModelsTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="varo-bank"
        sectionRef={varoBank}
        visible={visibleSections.includes(varoBank.current)}
        index={2}
        title="Varo Bank"
        description="Varo is an entirely new kind of bank. ... The banking experts provide decades of industry experience, and the tech experts can build a mobile app that offers everyone access to premium banking, including quicker access to your money, high-yield savings, and automatic saving tools."
        buttonText="View Project"
        buttonLink="https://www.varomoney.com"
        model={{
          type: 'laptop',
          alt: 'Varo bank home page',
          textures: [
            {
              src: VaroBankHomePage,
              srcSet: `${VaroBankHomePage} 800w, ${VaroBankHomePage} 1440w`,
              placeholder: VaroBankHomePage,
            },
          ],
        }}
      />

      <ProjectSummary
        id="varo-money"
        alternate
        sectionRef={varoMoney}
        visible={visibleSections.includes(varoMoney.current)}
        index={3}
        title="Varo Money"
        description="Varo Money is a mobile banking business that helps customers cover their expenses, pay their bills, and build their wealth over time."
        buttonText="View Project"
        buttonLink="https://www.varomoney.com/"
        // buttonLink="/projects/device-models"
        model={{
          type: 'phone',
          alt: 'Varo money screenshots',
          textures: [
            {
              src: deviceModelsTextureLarge0,
              srcSet: `${deviceModelsTextureLarge0} 254w, ${deviceModelsTextureLarge0} 508w`,
              placeholder: deviceModelsTexturePlaceholder,
            },
            {
              src: VaroMoneyFeature,
              srcSet: `${VaroMoneyFeature} 254w, ${VaroMoneyFeature} 508w`,
              placeholder: deviceModelsTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="gamified-portal"
        sectionRef={gamifiedPortal}
        visible={visibleSections.includes(gamifiedPortal.current)}
        index={4}
        title="Gamified Onboarding portal"
        description="Created a web portal to improve onboarding experience with simplicity and Gamification. Made surveys and document submission interesting"
        buttonText="View Project"
        buttonLink="/projects/emtec-candidate-engagement-system"
        model={{
          type: 'laptop',
          alt: 'Preview of EmCES portal',
          textures: [
            {
              src: dttTexture,
              srcSet: `${dttTexture} 800w, ${dttTextureLarge} 1440w`,
              placeholder: dttTexturePlaceholder,
            },
          ],
        }}
      />

      <Skills
        sectionRef={skills}
        visible={visibleSections.includes(skills.current)}
        id="skills"
      />

      <Experience
        sectionRef={experience}
        visible={visibleSections.includes(awards.current)}
        id="experience"
        objectList={workingExp}
        title="Professional Experience"
      />

      <Achievements
        sectionRef={awards}
        visible={visibleSections.includes(awards.current)}
        id="awards"
        objectList={awardsAndAchievements}
        title="Awards and Achievements"
      />

      <Profile
        sectionRef={about}
        visible={visibleSections.includes(about.current)}
        id="about"
      />

      <Footer />
    </div>
  );
};

export default Home;
