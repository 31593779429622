import React from 'react';
import { Transition } from 'react-transition-group';
import { reflow } from 'utils/transition';
import Section from 'components/Section';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './Experience.css';
import { media } from 'utils/style';
import { useWindowSize } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Chip,
} from '@material-ui/core';
import { ExpandMore, LinkOutlined, LocationOn } from '@material-ui/icons';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    backgroundColor: 'rgb(var(--rgbBackground) / 0.2)',
    border: 'solid 1px rgb(var(--rgbPrimary))',
  },
}));

const Experience = ({ id, visible, sectionRef, objectList, title }) => {
  const titleId = `${id}-title`;
  const classes = useStyles();
  const { width } = useWindowSize();
  const [step, setStep] = useState(0);
  const isMobile = width <= media.tablet;
  window.browserWidth = width;
  return (
    <Section
      className="awards"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        <>
          <Grid container>
            <Grid item xs={12} className="center">
              <h4 className="heading--level-3 title" style={{ zIndex: 1 }}>
                {title}
              </h4>
            </Grid>
            <Grid item xs={12} className="center">
              <Grid item xs={12} lg={8}>
                {objectList.map((element, index) => (
                  <div className="paper accordian" key={index}>
                    <Badge
                      // badgeContent="4+ years"
                      color="error"
                      style={{ display: 'block' }}
                    >
                      <Accordion className={classes.paper} expanded={index === step}>
                        <AccordionSummary
                          expandIcon={<ExpandMore className="text" />}
                          onClick={() => (index === step ? setStep(NaN) : setStep(index))}
                          // style={{
                          //   background:
                          //     index === step
                          //       ? 'rgb(var(--rgbBackground) / 0.5)'
                          //       : 'transparent',
                          // }}
                        >
                          <Grid item xs={12} lg={9}>
                            <Typography variant="h6" component="h4" className="heading">
                              {element.title} <i>@</i> {element.company}
                            </Typography>
                          </Grid>

                          {!isMobile && (
                            <Grid item lg={3}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className="date"
                              >
                                {element.date}
                              </Typography>
                            </Grid>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item xs={12} lg={10}>
                              <Grid container>
                                <Grid item xs={6} className="text flex exp-info-item">
                                  <LocationOn /> <span>{element.location}</span>
                                </Grid>
                                <Grid item xs={6} className="text flex">
                                  <LinkOutlined />{' '}
                                  <a
                                    href={element.link ?? '#'}
                                    className="link company-link"
                                  >
                                    {element.company}
                                  </a>
                                </Grid>
                              </Grid>
                              <div className="text text--size-s role-desc">
                                {element.description}
                              </div>
                              <div className="mt-8">
                                {element.tech?.map(tec => (
                                  <Chip
                                    size="small"
                                    label={tec}
                                    className="chip"
                                    key={tec}
                                  />
                                ))}
                              </div>
                            </Grid>
                            {!isMobile && (
                              <Grid item xs={12} lg={2}>
                                {element.icon}
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Badge>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      </Transition>
    </Section>
  );
};

export default Experience;
