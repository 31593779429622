import KohlerIcon from 'assets/kohler-logo-white.png';
import EmtecIcon from 'assets/emtec-inc.png';

export const awardsAndAchievements = [
  {
    date: '2022',
    icon: '📜',
    title: 'Peer to Peer award, Varo Bank, NA',
    description:
      'Got Nominated by colleagues for Leadership, Technical Excellence, hard work and dedication to furthering the mission and success of the company.',
  },
  {
    date: '2021',
    icon: '🥇',
    title: 'CEO choice award, Varo Bank, NA',
    description:
      'CEO choice award in a Hackathon 2021 organized by Varo. Developed a chatbot using React and AWS LEX to facilitate customer query resolution',
  },
  {
    date: '2020',
    icon: '📜',
    title: 'Excellence award',
    description:
      'In recognition of delivering excellent performance in Customer Migration Process from Varo Money to Varo Bank',
  },
  {
    date: '2020',
    icon: '📜',
    title: 'Spot award',
    description: 'In recognition of writing unit tests to improve code coverage to 98%',
  },
];

export const experience = [
  {
    date: `Aug 2022 - Present`,
    icon: <img src={KohlerIcon} width="100" alt="Kohler Logo" />,
    title: 'Senior Software Engineer',
    company: 'Kohler Co.',
    location: 'Remote',
    link: 'https://www.kohler.co.in/',
    description: `Developed the UI and UX for an E-Commerce website of Kohler's B2B Business`,
    tech: [
      'Next JS',
      'React JS',
      'Contentful CMS',
      'Redux Toolkit',
      'Tailwind CSS',
      'Commerce Tool',
    ],
  },
  {
    date: `Apr 2022 - Aug 2022`,
    icon: <img src={EmtecIcon} alt="React JS" className="company-logo" />,
    title: 'Senior Software Engineer',
    company: 'Emtec Inc.',
    link: 'https://www.emtecinc.com/',
    location: 'Pune',
    tech: [
      'React JS',
      'GraphQL',
      'React hooks',
      'Context API',
      'TypeScript',
      'Material UI',
      'Figma',
      'Jest',
    ],
    description: (
      <>
        Worked on a SPA for a US based Fintech client using ReactJS <br />
        Developed UI component library. <br />
        Delivered features like Referrals program, Promotional offers, Plaid external
        account linking <br />
        Worked on CI-CD for Angular, C# applications
      </>
    ),
  },
  {
    date: 'Apr 2021 - Apr 2022',
    icon: <img src={EmtecIcon} alt="React JS" className="company-logo" />,
    company: 'Emtec Inc.',
    link: 'https://www.emtecinc.com/',
    location: 'Pune',
    title: 'Software Engineer',
    tech: [
      'React JS',
      'GraphQL',
      'React hooks',
      'Context API',
      'Material UI',
      'SCSS',
      'Figma',
      'Jest',
    ],
    description: (
      <>
        Worked on a customer facing SPA for the US based Fintech client to develop highly
        responsive web pages.
        <br />
        Worked on revamping the existing UI as per new Design requirements. Using Jest and
        Enzyme achieved 98-99% of code test coverage.
        <br />
        Worked on CI-CD pipeline for Marketing websites
      </>
    ),
  },
  {
    date: 'Dec 2018 - Oct 2019',
    icon: <img src={EmtecIcon} alt="React JS" className="company-logo" />,
    company: 'Emtec Inc.',
    link: 'https://www.emtecinc.com/',
    location: 'Pune',
    title: 'Trainee Software Engineer ',
    description: (
      <>
        Developed a Gamified portal to onboard recruits by feedback forms, missions to
        provide required documents and interactive experience using Angular PWA. Completed
        end to end CI-CD for the same project
      </>
    ),
    tech: [
      'Angular 6',
      'PrimeNG',
      'C# API',
      'Azure DevOps',
      'Entity Framework',
      'Adobe XD',
      'SQL Server',
    ],
  },
];
